import React, { useEffect, useState, useRef } from "react";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { useForm, Controller } from "react-hook-form";
import Multiselect from "react-bootstrap-multiselect";
import ComponentList from "./ComponentList";
import Select from "react-dropdown-select";
import { useToken } from "../../store";
import { GetApi, PostApi } from "../../Api/Dropdown";
import SupplierList from "./SupplierList";
import Swal from "sweetalert2";
const SupplierComponent = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const data = [
    {
      id: 1,
      name: "Leanne Graham",
    },
    {
      id: 2,
      name: "Ervin Howell",
    },
  ];

  const onSubmit = async (data, e) => {
    console.log(data);
    let response = await PostApi("/masters/supplierCreate", data, token);
    console.log(response);
    // Swal.fire(response.data.status.code, response.data.primary.message,response.data.status.code)
  };
  const [showResults, setShowResults] = useState(false);
  const [resultMessage, setResultMessage] = useState({});
  const [compDropdown, setComponentDropDown] = useState([]);
  const [supDropdown, setSupplierDropDown] = useState([]);
  const token = sessionStorage.getItem("token");

  const fetchData = async () => {
    try {
      const response = await GetApi(
        "/masters/getComponentInfoForEverySupplier",
        token
      );
      let drop = [];
      response.data.primary.map((d, i) =>
        drop.push({
          componentId: d.componentId,
          componentCode: d.componentCode,
          componentName: d.componentName,
        })
      );
      setComponentDropDown(drop);
    } catch (error) {
      console.error("Failed to fetch component info:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="planning">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h2 class="m-0 text-dark">Supplier Master</h2>
        </div>
      </div>
      <div className="card">
        <div class="card-body">
          <Tabs activeHeaderStyle={{ background: "transparent" }}>
            <Tab label="Supplier List">
              <SupplierList></SupplierList>
            </Tab>
            <Tab label="Create Supplier Master">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-9">
                    {showResults ? (
                      <Results
                        key={Math.random()}
                        message={resultMessage.message}
                        error={resultMessage.error}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor className="labelText">
                        Supplier Code
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter Supplier Code"
                        name="supplierCode"
                        {...register("supplierCode", {
                          required: "Please enter Component Name",
                        })}
                      />
                      {errors.supplierCode && (
                        <span className="err-msg">
                          {errors.supplierCode.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor className="labelText">
                        Supplier Name
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter Supplier Name"
                        name="supplierName"
                        {...register("supplierName", {
                          required: "Please Enter Supplier Name",
                        })}
                      />
                      {errors.supplierName && (
                        <span className="err-msg">
                          {errors.supplierName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor className="labelText">
                        Site Name
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter Site Name"
                        name="siteName"
                        {...register("siteName", {
                          required: "Please enter Site Name",
                        })}
                      />
                      {errors.siteName && (
                        <span className="err-msg">
                          {errors.siteName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor className="labelText">
                        Admin Email ID
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter Supplier Email ID"
                        name="adminMailId"
                        {...register("adminMailId", {
                          required: "Enter Model Number",
                        })}
                      />
                      {errors.adminMailId && (
                        <span className="err-msg">
                          {errors.adminMailId.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor className="labelText">
                        Mobile Number
                      </label>
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        placeholder="Enter Mobile Number"
                        name="mobileNumber"
                        {...register("mobileNumber", {
                          required: "Please enter Mobile Number",
                          validate: (value) =>
                            value.length === 10 ||
                            "Mobile Number must be exactly 10 digits",
                        })}
                      />
                      {errors.mobileNumber && (
                        <span className="err-msg">
                          {errors.mobileNumber.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor className="labelText">
                        State/Province/County
                      </label>
                      <input
                        type="textarea"
                        className="form-control form-control-sm"
                        placeholder="Enter state"
                        name="state"
                        {...register("state", {
                          required: "Please enter state",
                        })}
                      />
                      {errors.state && (
                        <span className="err-msg">{errors.state.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor className="labelText">
                        Country
                      </label>
                      <input
                        type="textarea"
                        className="form-control form-control-sm"
                        placeholder="Enter Country"
                        name="country"
                        {...register("country", {
                          required: "Please enter country",
                        })}
                      />
                      {errors.country && (
                        <span className="err-msg">
                          {errors.country.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor className="labelText">
                        City
                      </label>
                      <input
                        type="textarea"
                        className="form-control form-control-sm"
                        placeholder="Enter city"
                        name="city"
                        {...register("city", {
                          required: "Please enter city",
                        })}
                      />
                      {errors.city && (
                        <span className="err-msg">{errors.city.message}</span>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor className="labelText">
                        Street
                      </label>
                      <input
                        type="textarea"
                        className="form-control form-control-sm"
                        placeholder="Enter Street"
                        name="street"
                        {...register("street", {
                          required: "Please enter street",
                        })}
                      />
                      {errors.street && (
                        <span className="err-msg">{errors.street.message}</span>
                      )}
                    </div>
                  </div> */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor className="labelText">
                        Postal Code
                      </label>
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        placeholder="Enter postalCode"
                        name="postalCode"
                        {...register("postalCode", {
                          required: "Please enter postalCode",
                          validate: (value) =>
                            value.length === 6 ||
                            "Postal Code should be 6 digits",
                        })}
                      />
                      {errors.postalCode && (
                        <span className="err-msg">
                          {errors.postalCode.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="componentArray" className="labelText">
                        Components
                      </label>
                      <Controller
                        name="componentArray"
                        control={control}
                        rules={{ required: "Please Select Components" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={compDropdown}
                            labelField="componentName"
                            valueField="componentId"
                            multi={true}
                          />
                        )}
                      />
                      {errors.componentArray && (
                        <span className="err-msg">
                          {errors.componentArray.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="submit"
                      value="Submit"
                      class="btn btn-primary newBtn"
                    />
                    <input
                      type="reset"
                      value="Reset"
                      class="btn btn-secondary newBtn"
                    />
                  </div>
                </div>
              </form>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const Results = (props) => {
  let error, classes;
  if (props.error === false) {
    error = "Success";
    classes = "alert alert-success alert-dismissible fade show";
  } else {
    error = "Error!";
    classes = "alert alert-danger alert-dismissible fade show";
  }
  return (
    <div className="results">
      <div className={classes}>
        <strong>{error}</strong> {props.message}
        <button type="button" class="close" data-dismiss="alert">
          &times;
        </button>
      </div>
    </div>
  );
};

export default SupplierComponent;
