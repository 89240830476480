import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { useForm, Controller } from "react-hook-form";
import ComponentList from "./ComponentList";
import { Button, Typography } from "@mui/material";
import { GetApi, PostApi } from "../../Api/Dropdown";
import { BaseUr, BaseUrl } from "../../Api/Endpoints";
import Swal from "sweetalert2";
import { useToken } from "../../store";

const ComponentMaster = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const token = sessionStorage.getItem("token");
  console.log(token);

  const [compDropdown, setComponentDropDown] = useState([]);

  // const getcomponents = async()=>{
  //   const result = await GetApi("masters/getComponentInfoForEverySupplier", token);

  //   console.log(result);
  // }

  const fetchData = async () => {
    try {
      const data = await GetApi("masters/getComponentInfoForEverySupplier", token);
      console.log("data is ", data);
      setComponentDropDown(data.data.primary);
    } catch (error) {
      console.error("Failed to fetch site list:", error);
    }
  };

  const [showResults, setShowResults] = useState(false);
  const [resultMessage, setResultMessage] = useState({});
  const [boqStatus, setBoqStatus] = useState("no");
  const [compComposition, setCompComposition] = useState([
    { componentId: "", quantity: "" },
  ]);

  const onSubmit = async (data, e) => {
    const formData = {
      ...data,
      boqStatus: boqStatus === "yes" ? "Yes" : "No",
      compComposition: boqStatus === "yes" ? compComposition : [],
    };

    let submit = await PostApi(
      BaseUrl + "masters/componentCreate",
      formData,
      token
    );

    Swal.fire(
      submit.data.status.code,
      submit.data.status.message,
      submit.data.status.code
    );
  };

  

  const addComponent = () => {
    setCompComposition([...compComposition, { componentId: "", quantity: "" }]);
  };

  const handleCompChange = (index, field, value) => {
    const updatedComps = compComposition.map((comp, i) =>
      i === index ? { ...comp, [field]: value } : comp
    );
    setCompComposition(updatedComps);
  };

  const removeComponent = (index) => {
    setCompComposition(compComposition.filter((_, i) => i !== index));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="planning">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h2 className="m-0 text-dark">Components Master</h2>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Tabs activeHeaderStyle={{ background: "transparent" }}>
            <Tab label="Components List">
              <ComponentList />
            </Tab>
            <Tab label="Create Component Master">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-9">
                    {showResults && (
                      <Results
                        key={Math.random()}
                        message={resultMessage.message}
                        error={resultMessage.error}
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="componentName" className="labelText">
                        Component Name
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter Component Name"
                        name="componentName"
                        {...register("componentName", {
                          required: "Please enter Component Name",
                        })}
                      />
                      {errors.componentName && (
                        <span className="err-msg">
                          {errors.componentName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="componentCode" className="labelText">
                        Component Code
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter Component Code"
                        name="componentCode"
                        {...register("componentCode", {
                          required: "Please enter Component Code",
                        })}
                      />
                      {errors.componentCode && (
                        <span className="err-msg">
                          {errors.componentCode.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="category" className="labelText">
                        Category
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter Category"
                        name="category"
                        {...register("category", {
                          required: "Enter Category",
                        })}
                      />
                      {errors.category && (
                        <span className="err-msg">
                          {errors.category.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="componentDescription"
                        className="labelText"
                      >
                        Component Description
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter Component Description"
                        name="componentDescription"
                        {...register("componentDescription", {
                          required: "Please enter Component Description",
                        })}
                      />
                      {errors.componentDescription && (
                        <span className="err-msg">
                          {errors.componentDescription.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {/* BOQ Status */}
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <div class="form-check">
                        <input
                          className="form-check-input labelText"
                          type="checkbox"
                          value=""
                          onChange={(e) => {
                            if (e.target.checked) {
                              setBoqStatus('yes');
                            }
                            else{
                              setBoqStatus('no')
                            }
                          }}    
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label "
                          for="flexCheckDefault"
                        >
                          Assembly
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Conditional Components Composition */}
                {boqStatus === "yes" && (
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{ fontFamily: "Inter", fontWeight: "bold" }}
                        >
                          Components Composition
                        </Typography>
                        <Button
                          size="small"
                          variant="contained"
                          color="success"
                          onClick={addComponent}
                        >
                          Add Component
                        </Button>
                      </div>
                      {compComposition.map((comp, index) => (
                        <div className="row" key={index}>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="labelText">Component ID</label>

                              <select
                                class="form-select form-select-sm"
                                name="componentCode"
                                value={comp.componentId}
                                onChange={(e) =>
                                  handleCompChange(
                                    index,
                                    "componentId",
                                    e.target.value
                                  )
                                }
                              >
                                <option selected>Select Component</option>
                                {compDropdown.map((data, index) => (
                                  <option
                                    key={index}
                                    value={data.componentId}
                                    // onSelect={(data) =>
                                    //   fetchSuppliers(data.target.value)
                                    // }
                                  >
                                    {data.componentName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="labelText">Quantity</label>
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                placeholder="Enter Quantity"
                                value={comp.quantity}
                                onChange={(e) =>
                                  handleCompChange(
                                    index,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <Button
                              sx={{ mt: 4 }}
                              size="small"
                              variant="contained"
                              color="error"
                              onClick={() => removeComponent(index)}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-primary newBtn"
                    />
                    <input
                      type="reset"
                      value="Reset"
                      className="btn btn-secondary newBtn"
                    />
                  </div>
                </div>
              </form>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const Results = (props) => {
  let error, classes;
  if (props.error === false) {
    error = "Success";
    classes = "alert alert-success alert-dismissible fade show";
  } else {
    error = "Error!";
    classes = "alert alert-danger alert-dismissible fade show";
  }
  return (
    <div className="results">
      <div className={classes}>
        <strong>{error}</strong> {props.message}
        <button type="button" className="close" data-dismiss="alert">
          &times;
        </button>
      </div>
    </div>
  );
};

export default ComponentMaster;
