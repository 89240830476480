import DataTable from "react-data-table-component";
import React, { useEffect, useState, useRef } from "react";
import { GetApi } from "../../Api/Dropdown";
import { BaseUr } from "../../Api/Endpoints";
import qr from "../../assets/images/QR.png";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import {useToken} from "../../store";
import CustomStyles from "../../CustomStyles";

const InwardGoodsList = () => {
  const LinesComponent = ({ data }) => {
    const [showImg, setShowImg] = useState(false);
    const [show, setShow] = useState(false);

    const subColumns = [
      {
        name: "QR Code",
        sortable: true,
        minWidth: "130px",
        cell: (row) => (
          <>
            <img
              src={qr}
              style={{ cursor: "pointer" }}
              alt="QR Code"
              width={20}
              height={20}
              onClick={() => {
                setShowImg(row);
                console.log(row);
                setShow(true);
              }}
            />
          </>
        ),
      },
      {
        name: "Component ID",
        selector: (row) => row.componentId,
        sortable: true,
        minWidth: "130px",
      },
      {
        name: "GRN Number",
        selector: (row) => row.grnNumber,
        sortable: true,
        minWidth: "130px",
      },
      {
        name: "Line Number",
        selector: (row) => row.lineNumber,
        sortable: true,
        minWidth: "130px",
      },
      {
        name: "Batch Number",
        selector: (row) => row.batchNumber,
        sortable: true,
        minWidth: "130px",
      },
      {
        name: "Batch Date",
        selector: (row) => row.batchDate,
        sortable: true,
        minWidth: "130px",
      },
      {
        name: "Serial Number",
        selector: (row) => row.serialNumber,
        sortable: true,
        minWidth: "130px",
      },
      {
        name: "Allocated Status",
        selector: (row) => row.allocatedStatus,
        sortable: true,
        minWidth: "130px",
      },
    ];
    return (
      <>
        <DataTable
          columns={subColumns}
          data={data.inventoryLines}
          pagination
          dense
          selectableRowsHighlight="true"
          compact
          highlightOnHover="true"
          customStyles={CustomStyles}
        />
        <Modal show={show}>
          <Modal.Header closeButton>
            <Modal.Title>
              QR Code :- ComponentID - {showImg.componentId}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <img src={showImg.qrCode} style={{ width: "60%" }}></img>
            </center>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const columns = [
    {
      name: "Component ID",
      selector: (row) => row.componentId,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Component Code",
      selector: (row) => row.componentCode,
      sortable: true,
      minWidth: "130px",
    }, 
    {
      name: "Supplier ID",
      selector: (row) => row.supplierId,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Site Name",
      selector: (row) => row.siteName,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Remaining Quantity",
      selector: (row) => row.actualQuantity,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Unique Identifier",
      selector: (row) => row.uuid,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "PO Number",
      selector: (row) => row.poNumber,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "GRN Number",
      selector: (row) => row.grnNumber,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "GRN Date",
      selector: (row) => new Date(row.grnDate).toLocaleDateString(),
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Batch Number",
      selector: (row) => row.batchNumber,
      sortable: true,
      minWidth: "130px",
    },
  ];

  const [data, setData] = useState([]);
  const token =  sessionStorage.getItem("token");
  const getData = async () => {
    const response = await GetApi(
      "qrCode/componentWiseInventoryList",
      token
    );
    // console.log(response)
    setData(response.data.primary);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        //defaultSortField="templateID"
        pagination
        dense
        selectableRowsHighlight="true"
        compact
        highlightOnHover="true"
        customStyles={CustomStyles}
        expandableRows
        expandableRowsComponent={LinesComponent}
      />
    </>
  );
};

export default InwardGoodsList;
