import { Box, Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { GetApi, PostApi } from "../Api/Dropdown";
import { Tabs, Tab } from "react-bootstrap-tabs";
import FinalProdList from "./FinalProdList";
import {useToken} from "../store";
import Swal from "sweetalert2";
 
function FinalProduct() {
  
  const [supDropdown, setSupplierDropDown] = useState([]);
  const token =  sessionStorage.getItem("token");
const [compDropdown, setComponentDropDown] = useState([]);
const fetchData = async () => {
    
  try {
    const data = await GetApi("masters/getComponentDetailsForProduct",token);
    console.log("data is ", data);
    setComponentDropDown(data.data.primary);
  } catch (error) {
    console.error("Failed to fetch site list:", error);
  }
};



 

  useEffect(() => {
    fetchData();
  }, [] );

  const { control, handleSubmit, register,reset } = useForm({
    defaultValues: {
      finalProductCode: "",
      finalProductName: "",
      description: '',
      category: '',
      components: [{ componentId: "", quantity: "" }],
    },
  });

  const {
    fields: componentFields,
    append: appendComponent,
    remove: removeComponent,
  } = useFieldArray({
    control,
    name: "components",
  });

  const onSubmit = async(data) => {
    let CreateProduct = await  PostApi("masters/finalProductCreate",data,token);
    console.log(CreateProduct.data)
    reset()
    Swal.fire("success","Product has been created successfully","success")
  };

  const handleAddComponent = () => {
    appendComponent({ componentId: "", quantity: "" });
  };

  const handleRemoveComponent = (index) => {
    removeComponent(index);
  };

  return (
    <>
      <div className="row mb-2">
        <div className="col-sm-6">
          <h2 className="m-0 text-dark">Products</h2>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Tabs activeHeaderStyle={{ background: "transparent" }}>
            <Tab label="Products List">
              <FinalProdList />
            </Tab>
            <Tab label="Product Creation">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="labelText" htmlFor="prodID">
                        Product ID
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="finalProductCode"
                        {...register("finalProductCode")}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="labelText" htmlFor="prodName">
                        Product Name
                      </label>
                      <input
                        id="prodName"
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="Product Name"
                        {...register("finalProductName")}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="labelText" htmlFor="prodDesc">
                        Product Description
                      </label>
                      <input
                        id="prodDesc"
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="Product Description"
                        {...register("description")}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="labelText" htmlFor="prodCategory">
                        Product Category
                      </label>
                      <input
                        id="prodCategory"
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="Product Category"
                        {...register("category")}
                      />
                    </div>
                  </div>
                  <div className="col align-self-end">
                    <div className="form-group">
                      <Button
                        variant="contained"
                        type="button"
                        onClick={handleAddComponent}
                        size="small"
                      >
                        Add Component
                      </Button>
                    </div>
                  </div>
                </div>

                {componentFields.map((component, index) => (
                  <Paper
                    elevation={3}
                    key={component.id}
                    style={{ marginTop: "20px" }}
                    className="card-body"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "3px",
                      }}
                    >
                      <h5 style={{ fontFamily: "inter", fontWeight: "bold" }}>
                        Component {index + 1}
                      </h5>
                      <Button
                        variant="contained"
                        color="error"
                        type="button"
                        onClick={() => handleRemoveComponent(index)}
                        size="small"
                      >
                        Remove Component
                      </Button>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="labelText" htmlFor={`component-${index}`}>
                            Component ID
                          </label>
                          <select
                            className="form-select form-select-sm"
                            {...register(`components.${index}.componentId`)}
                           
                          >
                            <option value="">Select Component</option>
                            {compDropdown.map((data) => (
                              <option key={data.componentId} value={data.componentId}>
                                {data.componentName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                    

                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="labelText" htmlFor={`quantity-${index}`}>
                            Quantity
                          </label>
                          <input
                            type="number"
                            className="form-control form-control-sm"
                            placeholder="Quantity"
                            {...register(`components.${index}.quantity`)}
                          />
                        </div>
                      </div>
                    </div>
                  </Paper>
                ))}

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginTop: "20px" }}
                >
                  Submit
                </Button>
              </form>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default FinalProduct;
