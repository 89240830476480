import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { Tree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import { Form, Row, Col, AccordionItem, AccordionHeader, AccordionBody } from "react-bootstrap";
import Button from "@mui/material/Button";

import rootNode from "./trackingInfo";

import ListGroup from "react-bootstrap/ListGroup";
import { Box, Drawer, MenuItem, TextField, Typography } from "@mui/material";
import { Card } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";


import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";

import { Grid } from "@mui/material";

import { useLocation } from "react-router-dom";
import { GetApi, PostApi } from "../Api/Dropdown";
import Swal from "sweetalert2";

const DEFAULT_DEPTH = 9;

const cloneWithDepth = (object, depth = DEFAULT_DEPTH) => {
  if (depth === -1) return undefined;
  if (typeof object !== "object") return object;

  if (Array.isArray(object)) {
    return object
      .map((val) => cloneWithDepth(val, depth - 1))
      .filter((val) => val !== undefined);
  }

  const clone = {};

  for (const key in object) {
    if (typeof object[key] === "object" && depth - 1 === -1) {
      continue;
    }

    const clonedValue = cloneWithDepth(object[key], depth - 1);

    if (clonedValue !== undefined) {
      clone[key] = clonedValue;
    }
  }

  return clone;
};

const useWindowInnerSize = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const handleResize = useCallback(() => {
    setInnerWidth(window.innerWidth);
    setInnerHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return { innerWidth, innerHeight };
};

const TrackingParts = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const token = sessionStorage.getItem("token");
  const onSubmit = async (data) => {
    console.log("Form Data:", data);
    const response = await PostApi("finalProduct/serviceRequest", data, token);
    console.log(response)
    reset();
    showPlant(false);
    if(response.data.status.code === "SUCCESS"){
    Swal.fire("success",response.data.primary,"success")
    }
    else{
      Swal.fire("error",response.data.status.code.message,"error")
    }
  };

  const props = useLocation();
  const [useData]=useState(props.state)

  const [data, setData] = useState(cloneWithDepth(props.state));

  const [path, setPath] = useState([props.state.name]);

  const [canvasWidth, setCanvasWidth] = useState(0);

  const [canvasHeight, setCanvasHeight] = useState(0);

  const { innerWidth, innerHeight } = useWindowInnerSize();

  const canvasWrapper = useRef(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const setCanvasSize = useCallback(() => {
    if (canvasWrapper.current) {
      const { clientWidth, clientHeight } = canvasWrapper.current;
      setCanvasWidth(clientWidth);
      setCanvasHeight(clientHeight);
    }
  }, []);

  useEffect(() => {
    setCanvasSize(); // Set initial size
    window.addEventListener("resize", setCanvasSize); // Add resize listener

    return () => window.removeEventListener("resize", setCanvasSize); // Clean up listener on unmount
  }, [setCanvasSize]);

  useEffect(() => {
    setCanvasSize(); // Update size on window resize
  }, [innerWidth, innerHeight, setCanvasSize]);

  const changeNode = ({ node, path }) => {
    setPath(path);
    setData(node);
  };
  const findPathtoBack = (path) => {
    changeNode(findNodeForFinish(path));
  };
  const findNodeForFinish = (key, node = useData, parentPath = []) => {
    const path = [...parentPath, node.name];
    if (node.name === key) {
      return { node: cloneWithDepth(node), path };
    }
    if (Array.isArray(node.children)) {
      for (const child of node.children) {
        const found = findNodeForFinish(key, child, path);
        if (found) return found;
      }
    }
  };

  const [plant, showPlant] = useState(false);
  const handleplantClose = () => showPlant(false);
  const [nodeDetails, setNodeDetails] = useState([]);
  const [qrcode,setQrcode]=useState("")
  const qrCode = async (id) =>{
    let qr = await GetApi(`reports/getQRCodeDetailsBasedOnEveryComponent?componentId=${id}`)
    if(qr.data.primary)
    setQrcode(()=>qr.data.primary[0]!==undefined?qr.data.primary[0].qrCode:" ")
  }
  const handleClick = (event, key) => {
    showPlant(true);
    let result = findNodeForFinish(key);

    console.log("Result is ....", result.node);
    setNodeDetails(result.node);
    // qrCode(result.node.id)
    setQrcode(result.node.qrCode  )
    setValue("componentId", result.node.id);
    setValue("serviceStatus", "sendToRepair");
    const { node, path } = result;
    changeNode({ node, path });
  };

  return (
    <>
      <div className="row mb-2">
        <div className="col-sm-6">
          <h2 className="m-0 text-dark">TRACKING</h2>
        </div>
      </div>
      <div className="card">
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="ml-3 mt-2">
            <label>Current Path(Node) : </label>
            <div className="mt-1">
              {path.map((path) => (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    ml: 2,
                    mb: 1,

                    cursor: data.name === path ? "" : "pointer",
                    color: data.name === path ? "white" : "black",
                    // textDecoration: "none"
                  }}
                  key={path}
                  // onClick={() => changeNode(findNode(path))}
                  onClick={() => findPathtoBack(path)}
                >
                  {path}
                </Button>
              ))}
            </div>
          </div>
          <div
            style={{
              flexGrow: 1,
              height: "100vh",
              display: "flex",
              alignItems: "flex-start",
            }}
            ref={canvasWrapper}
          >
            <div
              style={{
                width: canvasWidth,
                height: canvasHeight,
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Tree
                animated
                data={data}
                width={canvasWidth}
                height={canvasHeight}
                nodeRadius={12}
                gProps={{ className: "node", onClick: handleClick }}
                margins={{ top: 20, bottom: 10, left: 20, right: 200 }}
                onHover={(e) => console.log(e)}
              />
            </div>
          </div>
        </div>
      </div>

      <Drawer open={plant} onClose={handleplantClose} anchor={"right"}>
      <CloseIcon
      color="red"
                  onClick={handleplantClose}
                  sx={{
                    alignSelf: "end",
                    color: "black",
                    cursor: "pointer",
                    m:3,
                    mb:0,
                  }}
                />
        
        <Accordion defaultActiveKey="0">
      
        <AccordionItem eventKey="2">
            <AccordionHeader>QR Code</AccordionHeader>
            <AccordionBody>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
            <img src={qrcode} style={{ width: "60%" }} alt="QR Code" />
            </div>
            </AccordionBody>
          </AccordionItem>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "300px", // Set the fixed width here
                }}
              >
                <div>{nodeDetails.name} </div>

               
              </Box>
            </Accordion.Header>
            <Accordion.Body>
              <Card>
                <Card.Header as="h6">
                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>{nodeDetails.name} </div>

                    <CloseIcon
                      onClick={handleplantClose}
                      sx={{
                        alignSelf: "end",
                        color: "black",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </Card.Header>
                <Card.Body>
                  <ListGroup as="ol" numbered>
                    <ListGroup.Item
                      as="li"
                      style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                      Component Code : {nodeDetails.code}
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                      Component Name : {nodeDetails.name}
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                      Quantity : {nodeDetails.quantity}
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Repair</Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group  controlId="formDescription">
                  <Form.Label  className="labelText">
                    Description
                  </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Enter description"
                      {...register("description", {
                        required: "Description is required",
                      })}
                      isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description?.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group  controlId="formComponentId">
                 
                 
                  <Form.Label className="labelText">
                    Component ID
                  </Form.Label>
                    <Form.Control
                      size="sm"
                      disabled
                      type="text"
                      placeholder="Enter Component ID"
                      {...register("componentId", {
                        required: "Component ID is required",
                      })}
                      isInvalid={!!errors.componentId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.componentId?.message}
                    </Form.Control.Feedback>
                 
                </Form.Group>

                <Form.Group  controlId="formServiceProviderType">
                 
                
                  <Form.Label className="labelText" >
                    Service Provider Type
                  </Form.Label>
                    <Form.Control
                      as="select"
                      size="sm"
                      {...register("serviceProviderType", {
                        required: "Service Provider Type is required",
                      })}
                      isInvalid={!!errors.serviceProviderType}
                    >
                      <option value="">Select Service Provider Type</option>
                      <option value="INTERNAL">Internal</option>
                      <option value="EXTERNAL">External</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.serviceProviderType?.message}
                    </Form.Control.Feedback>
                  
                </Form.Group>

             
                <Form.Group >
                
                    <Button
                      type="submit"
                    color="success"
                      variant="contained"
                      size="sm"
                      sx={{ width: "100px",mt:2 }}
                    >
                      Submit
                    </Button>
                
                </Form.Group>
              </Form>
            </Accordion.Body>
          </Accordion.Item>

         
        </Accordion>
      </Drawer>
    </>
  );
};

export default TrackingParts;
