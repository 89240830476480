import { useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button, Col } from "react-bootstrap";
import Bgimage from "../assets/images/Auth/LoginBackground.jpg";
import icon from "../assets/images/Auth/logowhite.png";
import {useToken,useDetails} from "../store";
import axios from "axios";
import qs from "qs";

import "../App.css";
import { useNavigate } from "react-router-dom";
import { GetApi } from "../Api/Dropdown";

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [showErrorMessage, setShowErroMessage] = useState(false);
  const [errorMessage, setErroMessage] = useState("");
  const nav = useNavigate();
 


  const getUserDetails = async (token) => {
    const userDetails = await GetApi("masters/getLoggedUserInfo", token);
    console.log(userDetails);
    sessionStorage.setItem("details",userDetails.data.primary[0].partyType)
    if(userDetails.data.primary[0].partyType=="SUPPLIER"){
      nav("/inventory")
    }
    else if(userDetails.data.primary[0].partyType=="ADMIN"){
    nav("/finalProd");
    }
    else{
      nav("/service")
    }
  };
  const onSubmit = (data) => {
    // console.log(data);

    const payload = qs.stringify({
      grant_type: "password",
      scope: "openid",
      client_id: "ui_viewer",
      username: data.email,
      password: data.password,
    });

    const config = {
      method: "post",
      url: "https://auth.innovation.realware.app/realms/Tracking/protocol/openid-connect/token",

      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        sessionStorage.setItem("token",response.data.access_token);
        getUserDetails(response.data.access_token);
       
        
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <div
      className="vh-100 vw-100 "
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "80%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "whitesmoke",
          borderRadius: "8px",
          boxShadow: "0 8px 8px rgba(0, 0, 0, 0.4)",
        }}
      >
        <div
          style={{
            width: "40%",
            height: "100%",
            padding: "5px",
            backgroundImage: `url(${Bgimage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="left-content" style={{ height: "100%" }}>
            <div className="logo-placeholder">
              <span className="icon">
                <img src={`${icon}`} width={45}></img>
              </span>
              <br></br>
              <span className="logoText">Component Tracking</span>
            </div>
            <div className="text">
              <p style={{ fontSize: 14 }}>Inventory Precision, Site-wide</p>
              <p>
                "Master your assets with our Site Inventory Management System,
                ensuring every item's precise tracking and optimal deployment."
              </p>
            </div>
            <div className="copy">
              <p>
                Copyrights @2023, Real Variable Digital Asset Services, All
                rights reserved
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "60%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} sm={8} md={8}>
                <h2>Welcome back!</h2>
                <p style={{ color: "grey" }}>
                  Please enter your credentials to continue!
                </p>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group
                    className="mb-3"
                    size="sm"
                    controlId="formBasicEmail"
                  >
                    <Form.Label style={{ fontWeight: "bold" }}>
                      Email address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter email"
                      {...register("email", { required: true })}
                    />
                    {errors.email && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      {...register("password", { required: true })}
                    />
                    {errors.password && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember Me" />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Login;
