import React, { useEffect, useState } from "react";
import { GetApi, PostApi } from "../../Api/Dropdown";
import DataTable from "react-data-table-component";
import CustomStyles from "../../CustomStyles";
import { Button } from "@mui/material";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form"; // Import React Hook Form
import Swal from "sweetalert2";

function Service() {
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // Use useForm hook from react-hook-form
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    defaultValues: {
      description: "",
      componentId: "",
      serviceStatus: "Replaced",
      completionDate: "",
    },
  });

  const handleShow = (row) => {
    setValue("componentId", row.componentId); // Set componentId when modal is opened
    setShow(true);
  };

  const onSubmit = async (formData) => {
    console.log(formData);
    const response = await PostApi(
      "finalProduct/updateServiceRequest",
      formData,
      token
    );
    fetchData();
    if(formData.serviceStatus == "Replaced"){
        Swal.fire("success",`Component can't be repaired  ${formData.serviceStatus} successfully`,"success")
    }
    else{
   Swal.fire("success",`Component has been ${formData.serviceStatus} successfully`,"success")
}
    handleClose(); // Close modal on form submit
    reset(); // Reset the form
  };

  const columns = [
    {
      name: "Service Request ID",
      selector: (row) => row.serviceRequestId,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Service Description",
      selector: (row) => row.serviceDescription,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Request Date",
      selector: (row) => new Date(row.requestDate).toLocaleDateString(),
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Requested By",
      selector: (row) => row.requestedBy,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Service Provider Type",
      selector: (row) => row.serviceProviderType,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Service Provider ID",
      selector: (row) => row.serviceProviderId,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Service Provider Name",
      selector: (row) => row.serviceProviderName,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Service Status",
      selector: (row) => row.serviceStatus,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Component ID",
      selector: (row) => row.componentId,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Component Name",
      selector: (row) => row.componentName,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Component Code",
      selector: (row) => row.componentCode,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Action",
      sortable: false,
      minWidth: "150px",
      cell: (row) => (
        <Button
          variant="contained"
          color="success"
          onClick={() => handleShow(row)}
        >
          Action
        </Button>
      ),
    },
  ];

  const token = sessionStorage.getItem("token");
  const fetchData = async () => {
    try {
      const response = await GetApi(
        "finalProduct/loggedUserServiceRequestDetails",
        token
      );
      console.log(response);
      setData(response.data.primary);
  
    } catch (error) {
      console.error("Failed to fetch component info:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="planning">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h2 className="m-0 text-dark">Service</h2>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <DataTable
            columns={columns}
            data={data}
            customStyles={CustomStyles}
            pagination
            dense
            selectableRowsHighlight="true"
            compact
            highlightOnHover="true"
          />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="labelText">Service Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formDescription">
              <Form.Label className="labelText">Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter problem description"
                {...register("description", { required: "Description is required" })}
                isInvalid={!!errors.description}
              />
              {errors.description && (
                <Form.Control.Feedback type="invalid">
                  {errors.description.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="formComponentId">
              <Form.Label className="labelText">Component ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter component ID"
                {...register("componentId")}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="formServiceStatus">
              <Form.Label className="labelText" >Service Status</Form.Label>
              <Form.Control
                as="select"
                {...register("serviceStatus")}
              >
                <option value="Replaced">Replaced</option>
                <option value="Repaired">Repaired</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formCompletionDate">
              <Form.Label className="labelText" >Completion Date</Form.Label>
              <Form.Control
                type="date"
                className="mb-3"
                {...register("completionDate")}
              />
            </Form.Group>
            

            <Button variant="contained" color="success" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Service;
