
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { GetApi,PostApi } from "../Api/Dropdown";
import Swal from "sweetalert2";

function SupplierCompInward() {
    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        reset,
        formState: { errors },
      } = useForm({
        mode: "onChange",
      });
    
      const onSubmit = async (data, e) => {
        console.log("Form Data Submitted: ", data);
        const response = await PostApi(
          "qrCode/qrCodeGenerateForEveryComponent",
          data,
          token
        );
        if (response.data.status.code == "SUCCESS") {
          Swal.fire(
            response.data.status.code,
            response.data.status?.primary,
            response.data.status.code
          );
          reset();
        } else {
          Swal.fire(
            response.data.status.code,
            "Goods Creation Failed",
            response.data.status.code
          );
        }
      };

      const token = sessionStorage.getItem("token");

      
      const [compDropdownSup, setComponentDropDownSup] = useState([]);
      const [supDropdown, setSupplierDropDown] = useState([]);
    
    
    
      const fetchData = async () => {
        try {
          let api = `/masters/getComponentInfoForEverySupplier`;
          const response = await GetApi(api, token);
          console.log("data is ", response.data.primary);
          setComponentDropDownSup(response.data.primary);
        } catch (error) {
          console.error("Failed to fetch site list:", error);
        }
      };
    
      
      const fetchSite = async (id) => {
        try {
          let api = `/masters/getSiteInfoUnderSupplier?supplierId=${id}`;
          const response = await GetApi(api, token);
          console.log("data is Sites...", response.data.primary);
         
          setValue("siteName", response.data.primary[0].siteName);
        } catch (error) {
          console.error("Failed to fetch site list:", error);
        }
      };
    
      const fetchSuppliers = async (id) => {
        let api = `masters/supplierDropdown?componentId=${id}`;
       
        try {
          const response = await GetApi(api, token);
          
          setSupplierDropDown(response.data.primary);
        } catch (error) {
          console.error("Failed to fetch site list:", error);
        }
      };
    
      useEffect(() => {
        fetchData();
       
      }, []);
  return (
    <div> <form onSubmit={handleSubmit(onSubmit)}>
    
    <div className="row">
      {/* Supplier Code */}
      <div className="col-md-3">
        <div className="form-group">
          <label className="labelText" htmlFor={`componentId`}>
            Component ID
          </label>
          <select
            name="componentId"
            className="form-select form-select-sm"
            {...register(`componentId`)}
            onChange={(e) => fetchSuppliers(e.target.value)}
          >
            <option value="">Select Component</option>
            {compDropdownSup.map((data) => (
              <option
                key={data.componentId}
                value={data.componentId}
              >
                {data.componentName}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Goods Component Selection */}
      <div className="col-md-3">
        <div className="form-group">
          <label className="labelText" htmlFor={`supplierId`}>
            Supplier Name
          </label>
          <select
            class="form-select form-select-sm"
            name="supplierId"
            {...register(`supplierId`)}
            onChange={(e) => fetchSite(e.target.value)}
          >
            <option selected>Select Supplier</option>

            {supDropdown.map((data) => (
              <option
                key={data.supplierId}
                value={data.supplierId}
              >
                {data.supplierName}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Other Fields */}
      <div className="col-md-3">
        <div className="form-group">
          <label className="labelText">Site Name</label>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Enter Site Name"
            {...register("siteName", {
              required: "Please enter Site Name",
            })}
            onChange={(e) => fetchSite(e.target.value)}
          />
          {errors.siteName && (
            <span className="err-msg">
              {errors.siteName.message}
            </span>
          )}
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <label className="labelText">Quantity</label>
          <input
            type="number"
            className="form-control form-control-sm"
            placeholder="Enter Quantity"
            {...register("quantity", {
              required: "Please enter Quantity",
            })}
          />
          {errors.quantity && (
            <span className="err-msg">
              {errors.quantity.message}
            </span>
          )}
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <label className="labelText">Batch Number</label>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Enter Batch Number"
            {...register("batchNumber", {
              required: "Please enter Batch Number",
            })}
          />
          {errors.batchNumber && (
            <span className="err-msg">
              {errors.batchNumber.message}
            </span>
          )}
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <label className="labelText">UUID</label>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Enter UUID"
            {...register("uuid", {
              required: "Please enter UUID",
            })}
          />
          {errors.uuid && (
            <span className="err-msg">{errors.uuid.message}</span>
          )}
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <label className="labelText">PO Number</label>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Enter PO Number"
            {...register("poNumber", {
              required: "Please enter PO Number",
            })}
          />
          {errors.poNumber && (
            <span className="err-msg">
              {errors.poNumber.message}
            </span>
          )}
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <label className="labelText">GRN Number</label>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Enter GRN Number"
            {...register("grnNumber", {
              required: "Please enter GRN Number",
            })}
          />
          {errors.grnNumber && (
            <span className="err-msg">
              {errors.grnNumber.message}
            </span>
          )}
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <label className="labelText">GRN Date</label>
          <input
            type="date"
            className="form-control form-control-sm"
            placeholder="Enter GRN Date"
            {...register("grnDate", {
              required: "Please enter GRN Date",
            })}
          />
          {errors.grnDate && (
            <span className="err-msg">
              {errors.grnDate.message}
            </span>
          )}
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-md-12">
        <input
          type="submit"
          value="Submit"
          className="btn btn-primary newBtn"
        />
        <input
          type="reset"
          value="Reset"
          className="btn btn-secondary newBtn"
        />
      </div>
    </div>
  </form></div>
  )
}

export default SupplierCompInward