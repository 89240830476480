import React, { useEffect, useState } from "react";
import { GetApi, PostApi } from "../../Api/Dropdown";
import CustomStyles from "../../CustomStyles";
import DataTable from "react-data-table-component";
import { Button, IconButton } from "@mui/material";
import Swal from "sweetalert2";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

function ServiceHistory() {
  const token = sessionStorage.getItem("token");
  const [data, setData] = useState([]);
  const [subdata, setSubData] = useState([]);
  const [open, setOpen] = useState(false);

  const subcolumns = [
    {
      name: "Service Request ID",
      selector: (row) => row.serviceRequestId,
      sortable: true,
    },
    {
      name: "Service History ID",
      selector: (row) => row.serviceHistoryId,
      sortable: true,
    },
    {
      name: "Service Description",
      selector: (row) => row.serviceDescription,
    },
    {
      name: "Expected Completion Date",
      selector: (row) =>
        new Date(row.expectedCompletionDate).toLocaleDateString(),
    },
    {
      name: "Service Status",
      selector: (row) => row.serviceStatus,
      sortable: true,
    },
    {
      name: "Date Time",
      selector: (row) => row.dateTime,
    },
  ];

  const fetchData = async () => {
    try {
      const response = await GetApi("finalProduct/serviceHistoryList", token);
      console.log(response);
      setData(response.data.primary);
    } catch (error) {
      console.error("Failed to fetch component info:", error);
    }
  };

  const columns = [
    {
      name: "Parent Component ID",
      selector: (row) => row.parentComponentId,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Product ID",
      selector: (row) => row.productId,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Component ID",
      selector: (row) => row.componentId,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Component Name",
      selector: (row) => row.componentName,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Component Code",
      selector: (row) => row.componentCode,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Service Request ID",
      selector: (row) => row.serviceRequestId,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Service Description",
      selector: (row) => row.serviceDescription,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Expected Completion Date",
      selector: (row) => row.expectedCompletionDate || "N/A",
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Request Date",
      selector: (row) => new Date(row.requestDate).toLocaleDateString(),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Requested By",
      selector: (row) => row.requestedBy,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Service Provider Type",
      selector: (row) => row.serviceProviderType,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Service Status",
      selector: (row) => row.serviceStatus,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Service Provider ID",
      selector: (row) => row.serviceProviderId,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Service Provider Name",
      
      selector: (row) => row.serviceProviderName,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Date & Time",
      selector: (row) => row.dateTime,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Action",
      sortable: false,
      minWidth: "150px",
      cell: (row) => (
        <Button
        disabled={row.serviceStatus == "Approved"}
          variant="contained"
          color="success"
          onClick={() => handleAccept(row)}
        >
          Action
        </Button>
      ),
    },
    {
      name: "History",
      sortable: false,
      minWidth: "150px",
      cell: (row) => (
        <IconButton onClick={() => getServiceHistory(row)}>
          <PreviewIcon />
        </IconButton>
      ),
    },
  ];

  const getServiceHistory = async (data) => {
    try {
      const response = await GetApi(
        `finalProduct/serviceHistoryPopulateByIdList?serviceRequestId=${data.serviceRequestId}`,
        token
      );
      setSubData(response.data.primary);
      setOpen(true);
    } catch (error) {
      console.error("Failed to fetch service history:", error);
    }
  };

  const handleAccept = async (data) => {
    try {
      let payload = {
        componentId: data.componentId,
        serviceStatus: "Approved",
      };
      await PostApi("finalProduct/approveServiceRequest", payload, token);
      Swal.fire("Success", "Service request has been approved successfully", "success");
      fetchData(); // Refresh data after approval
    } catch (error) {
      Swal.fire("Error", "Failed to approve service request", "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="planning">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h2 className="m-0 text-dark">Service</h2>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <DataTable
            title="Service Requests"
            columns={columns} 
            data={data}
            pagination
            selectableRowsHighlight
            compact
            highlightOnHover
            customStyles={CustomStyles}
          />
        </div>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>Service History</DialogTitle>
        <DialogContent>
          <DataTable
            columns={subcolumns}
            data={subdata}
            pagination
            highlightOnHover
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ServiceHistory;
