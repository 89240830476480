import React from "react";
import moment from "moment";
const Footer = () => {
  return (
    <div>
      <footer class="main-footer">
        
          Copyright &copy; 2024-{moment().format('YYYY')} <a href="#">Real Variable Digital Asset Services</a>. All rights reserved.
      </footer>
    </div>
  );
};
export default Footer;
