import DataTable from "react-data-table-component";
import React, { useEffect, useState, useRef } from "react";
import { GetApi, PostApi } from "../../Api/Dropdown";
import { BaseUr } from "../../Api/Endpoints";
import qr from "../../assets/images/QR.png";
import { Col, Modal, Row } from "react-bootstrap";
import { Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useToken } from "../../store";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { json, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import CustomStyles from "../../CustomStyles";

const ProductInventory = () => {
  const [compDropdown, setComponentDropDown] = useState([]);
  const nav = useNavigate();

  const LinesComponent = ({ data }) => {
    const [showImg, setShowImg] = useState(false);
    const [show, setShow] = useState(false);

    const subColumns = [
      {
        name: "QR Code",
        sortable: true,
        minWidth: "130px",
        cell: (row) => (
          <>
            <img
              src={qr}
              style={{ cursor: "pointer" }}
              alt="QR Code"
              width={20}
              height={20}
              onClick={() => {
                setShowImg(row);
                console.log(row);
                setShow(true);
              }}
            />
          </>
        ),
      },
     
     
      {
        name: "Line Number",
        selector: (row) => row.productLineNumber,
        sortable: true,
        minWidth: "130px",
      },
     
      {
        name: "Serial Number",
        selector: (row) => row.productSerialNumber,
        sortable: true,
        minWidth: "130px",
      },
      {
        name: "Allocated Status",
        selector: (row) => row.allocatedStatus,
        sortable: true,
        minWidth: "130px",
      },
    ];
    return (
      <>
        <DataTable
          columns={subColumns}
          data={data.lines}
          pagination
          dense
          selectableRowsHighlight="true"
          compact
          highlightOnHover="true"
          customStyles={CustomStyles}
        />
        <Modal show={show}>
          <Modal.Header closeButton>
            <Modal.Title>
              QR Code :- ComponentID - {showImg.componentId}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <img src={showImg.qrCode} style={{ width: "60%" }}></img>
            </center>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const fetchData = async () => {
    try {
      const response = await GetApi("masters/finalProductDropDown", token);
      console.log(response)
      setComponentDropDown(response.data.primary);
    } catch (error) {
      console.error("Failed to fetch component info:", error);
    }
  };

  const [showbtn, setShowBtn] = useState(false);
  function ActionMenu({ row, onAction }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAction = async(action) => {
        handleClose();
        if (action === "View") {
            console.log(`${action} clicked for`, row);
            let baseurl=`masters/finalProductView?productId=${row.productId}`
            let data = await GetApi(baseurl,token)
            console.log(data.data.primary)
            nav('/tracking',{state:data.data.primary});
            onAction(action, row);
        }
    };

    return (
        <div>
            <IconButton aria-controls="action-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleAction('View')}>View</MenuItem>
                <MenuItem onClick={() => handleAction('Edit')}>Edit</MenuItem>
            </Menu>
        </div>
    );
}
  const columns = [
    {
      name: "Product ID",
      selector: (row) => row.productId,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Remaining Quantity",
      selector: (row) => row.actualQuantity,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: 'Action',
      sortable: false,
      minWidth: '150px',
      cell: (row) => (
          <ActionMenu
              row={row}
              onAction={(action, rowData) => {
              
                  console.log(action, rowData);
              }}
          />
      ),
  }
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const [data, setData] = useState([]);
  const token = sessionStorage.getItem("token");
  const getData = async () => {
    const response = await GetApi("masters/finalProductInventory", token);
    console.log(response)
    setData(response.data.primary);
  };

  const onSubmit = async (data) => {
   const parser = JSON.parse(data.productId)
    const payload = 
      {
        "productId" : parser.productId,
        "productCode": parser.productCode,
        "inventory" : data.inventory
    }
    
    const response = await PostApi("masters/addProductInventory",payload,token);
    console.log("Response is ....",response)
    if(response.data.status.code=="error"){
      
    Swal.fire(response.data.status.code,response.data.status.message,response.data.status.code);
    setShowBtn(false)
    reset();
    }else{
      Swal.fire("Succes",response.data.status.message,"success");
      reset();
      setShowBtn(false);
      
    }
  };

  useEffect(() => {
    getData();
    fetchData();
  }, []);

  return (
    <>
      <div>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              variant="contained"
              color="success"
              onClick={() => setShowBtn(true)}
            >
              Add
            </Button>
          </Col>
        </Row>
      </div>
      <DataTable
        columns={columns}
        data={data}
        //defaultSortField="templateID"
        pagination
        dense
        selectableRowsHighlight="true"
        compact
        highlightOnHover="true"
        expandableRows
        expandableRowsComponent={LinesComponent}
      />
      <Modal show={showbtn} onHide={() => setShowBtn(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Typography sx={{ fontFamily: "Inter", fontWeight: "bold" }}>
              Add Product Inventory
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelText" htmlFor={`component}`}>
                    Component ID
                  </label>
                  <select
                    className="form-select form-select-sm"
                    {...register(`productId`)}
                    onChange={(data) => console.log(data)}
                  >
                    <option value="">Select Component</option>
                    {compDropdown.map((data) => (
                      <option key={data.productId} value={JSON.stringify(data)}>
                        {data.productCode}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="componentCode" className="labelText">
                    Inventory
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Enter Quantity"
                    {...register("inventory", {
                      required: "Please enter Quantity ",
                    })}
                  />
                  {errors.inventory && (
                    <span className="err-msg">{errors.inventory.message}</span>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="contained"
              color="success"
              type="submit"
              sx={{ mr: 3 }}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowBtn(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ProductInventory;
