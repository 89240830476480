import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { useForm } from "react-hook-form";
// import ComponentList from "./ComponentList";
import Select from "react-dropdown-select";
import InwardGoodsList from "./Components/Master/InwardGoodsList";
import { GetApi, PostApi } from "./Api/Dropdown";
import { useDetails, useToken } from "./store";
import ProductInventory from "./Components/Master/ProductInventory";
import SuppilerInventory from "./Components/Master/SuppilerInventory";
import FinalSupplierInventory from "./Components/Master/FinalSupplierInventory";
import Swal from "sweetalert2";
import SupplierCompInward from "./Components/SupplierCompInward";

const InwardGoods = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data, e) => {
    console.log("Form Data Submitted: ", data);
    const response = await PostApi(
      "qrCode/qrCodeGenerateForEveryComponent",
      data,
      token
    );
    if (response.data.status.code == "SUCCESS") {
      Swal.fire(
        response.data.status.code,
        response.data.status?.primary,
        response.data.status.code
      );
      reset();
    } else {
      Swal.fire(
        response.data.status.code,
        "Goods Creation Failed",
        response.data.status.code
      );
    }
  };

  const onSubmitAdmin = async (data, e) => {
    console.log("Form Data Submitted: ", data);
    const response = await PostApi(
      "qrCode/qrCodeGenerateForOEMInward",
      data,
      token
    );
    if (response.data.status.code == "SUCCESS") {
      Swal.fire(
        response.data.status.code,
        response.data.primary,
        response.data.status.code
      );
      reset();
    } else {
      Swal.fire(
        response.data.status.code,
        "Goods Creation Failed",
        response.data.status.code
      );
    }
  };

  const details = sessionStorage.getItem("details");

  const [showResults, setShowResults] = useState(false);
  const [resultMessage, setResultMessage] = useState({});
  const token = sessionStorage.getItem("token");

  const [compDropdown, setComponentDropDown] = useState([]);
  const [compDropdownSup, setComponentDropDownSup] = useState([]);
  const [supDropdown, setSupplierDropDown] = useState([]);

  const fetchDataProduct = async () => {
    try {
      const data = await GetApi("masters/getComponentDetailsForProduct", token);
      console.log("data is ", data);
      setComponentDropDown(data.data.primary);
    } catch (error) {
      console.error("Failed to fetch site list:", error);
    }
  };

  const fetchData = async () => {
    try {
      let api = `/masters/getComponentInfoForEverySupplier`;
      const response = await GetApi(api, token);
      console.log("data is ", response.data.primary);
      setComponentDropDownSup(response.data.primary);
    } catch (error) {
      console.error("Failed to fetch site list:", error);
    }
  };

  const [site, setSite] = useState([]);
  const fetchSite = async (id) => {
    try {
      let api = `/masters/getSiteInfoUnderSupplier?supplierId=${id}`;
      const response = await GetApi(api, token);
      console.log("data is Sites...", response.data.primary);
      setSite(response.data.primary);
      setValue("siteName", response.data.primary[0].siteName);
    } catch (error) {
      console.error("Failed to fetch site list:", error);
    }
  };

  const fetchSuppliers = async (id) => {
    let api = `masters/supplierDropdown?componentId=${id}`;
    let details = `/masters/inwardComponentDetailsPrePopulateAtOEM?componentId=${id}`;
    try {
      const response = await GetApi(api, token);
      const detailsresponse = await GetApi(details, token);
      setValue("quantity", detailsresponse.data.primary[0].actualQuantity || 0);
      setValue("poNumber", detailsresponse.data.primary[0].poNumber || " ");
      setValue(
        "batchNumber",
        detailsresponse.data.primary[0].batchNumber || " "
      );
      console.log("data is ", response, detailsresponse);
      setSupplierDropDown(response.data.primary);
    } catch (error) {
      console.error("Failed to fetch site list:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataProduct();
  }, []);

  return (
    <div className="planning">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h2 className="m-0 text-dark">Inventory</h2>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          {details == "ADMIN" && (
            <Tabs activeHeaderStyle={{ background: "transparent" }}>
             
              <Tab label="Components Inward ">
                <form onSubmit={handleSubmit(onSubmitAdmin)}>
                  <div className="row">
                    <div className="col-md-9">
                      {showResults ? (
                        <Results
                          key={Math.random()}
                          message={resultMessage.message}
                          error={resultMessage.error}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    {/* Supplier Code */}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="labelText" htmlFor={`componentId`}>
                          Component Name
                        </label>
                        <select
                          name="componentId"
                          className="form-select form-select-sm"
                          {...register(`componentId`)}
                          onChange={(e) => fetchSuppliers(e.target.value)}
                        >
                          <option value="">Select Component</option>
                          {compDropdown.map((data) => (
                            <option
                              key={data.componentId}
                              value={data.componentId}
                            >
                              {data.componentName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Goods Component Selection */}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="labelText" htmlFor={`supplierId`}>
                          Supplier Name
                        </label>
                        <select
                          class="form-select form-select-sm"
                          name="supplierId"
                          {...register(`supplierId`)}
                          onChange={(e) => fetchSite(e.target.value)}
                        >
                          <option selected>Select Supplier</option>

                          {supDropdown.map((data) => (
                            <option
                              key={data.supplierId}
                              value={data.supplierId}
                            >
                              {data.supplierName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Other Fields */}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="labelText">Site Name</label>
                        <input
                          type="text"
                          disabled
                          className="form-control form-control-sm"
                          placeholder="Enter Site Name"
                          {...register("siteName", {
                            required: "Please enter Site Name",
                          })}
                        />
                        {errors.siteName && (
                          <span className="err-msg">
                            {errors.siteName.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="labelText">Quantity</label>
                        <input
                          type="number"
                          disabled
                          className="form-control form-control-sm"
                          placeholder="Enter Quantity"
                          {...register("quantity", {
                            required: "Please enter Quantity",
                          })}
                        />
                        {errors.quantity && (
                          <span className="err-msg">
                            {errors.quantity.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="labelText">Batch Number</label>
                        <input
                          type="text"
                          disabled
                          className="form-control form-control-sm"
                          placeholder="Enter Batch Number"
                          {...register("batchNumber", {
                            required: "Please enter Batch Number",
                          })}
                        />
                        {errors.batchNumber && (
                          <span className="err-msg">
                            {errors.batchNumber.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="labelText">Unique ID</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Enter UUID"
                          {...register("uuid", {
                            required: "Please enter UUID",
                          })}
                        />
                        {errors.uuid && (
                          <span className="err-msg">{errors.uuid.message}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="labelText">PO Number</label>
                        <input
                          type="text"
                          disabled
                          className="form-control form-control-sm"
                          placeholder="Enter PO Number"
                          {...register("poNumber", {
                            required: "Please enter PO Number",
                          })}
                        />
                        {errors.poNumber && (
                          <span className="err-msg">
                            {errors.poNumber.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="labelText">GRN Number</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Enter GRN Number"
                          {...register("grnNumber", {
                            required: "Please enter GRN Number",
                          })}
                        />
                        {errors.grnNumber && (
                          <span className="err-msg">
                            {errors.grnNumber.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="labelText">GRN Date</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          placeholder="Enter GRN Date"
                          {...register("grnDate", {
                            required: "Please enter GRN Date",
                          })}
                        />
                        {errors.grnDate && (
                          <span className="err-msg">
                            {errors.grnDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="submit"
                        value="Submit"
                        className="btn btn-primary newBtn"
                      />
                      <input
                        type="reset"
                        value="Reset"
                        className="btn btn-secondary newBtn"
                      />
                    </div>
                  </div>
                </form>
              </Tab>

              <Tab label="Components Inventory">
                <InwardGoodsList />
              </Tab>
              <Tab label="Finished Product Inventory ">
                <ProductInventory />
              </Tab>
            </Tabs>
          )}

          {details == "SUPPLIER" && (
            <Tabs activeHeaderStyle={{ background: "transparent" }}>
               <Tab label="Components Inward ">
                <SupplierCompInward />
              </Tab>
              <Tab label="Component Inventory">
                <SuppilerInventory />
              </Tab>
              <Tab label="Final Supplier Inventory">
                <FinalSupplierInventory />
              </Tab>
             
            </Tabs>
          )}
        </div>
      </div>
    </div>
  );
};

const Results = (props) => {
  let error, classes;
  if (props.error === false) {
    error = "Success";
    classes = "alert alert-success alert-dismissible fade show";
  } else {
    error = "Error!";
    classes = "alert alert-danger alert-dismissible fade show";
  }
  return (
    <div className="results">
      <div className={classes}>
        <strong>{error}</strong> {props.message}
        <button type="button" className="close" data-dismiss="alert">
          &times;
        </button>
      </div>
    </div>
  );
};

export default InwardGoods;
