import {atom,useAtom} from 'jotai';

const getToken = atom();
const getUserDetails = atom();

const useToken = () => useAtom(getToken);
const useDetails = () => useAtom(getUserDetails);



export  {useToken,useDetails}; 