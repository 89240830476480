import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { GetApi } from "../../Api/Dropdown";
import {useToken} from "../../store";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import CustomStyles from "../../CustomStyles";

const SupplierList = () => {
  const [data, setData] = useState([]);
  const token =  sessionStorage.getItem("token");

  // State for Modal visibility and selected components
  const [open, setOpen] = useState(false);
  const [selectedComponents, setSelectedComponents] = useState([]);

  // Fetch supplier data
  const getData = async () => {
    const response = await GetApi(
      "masters/getSupplierList",
      token
    );
    setData(response.data.primary);
  };

  useEffect(() => {
    getData();
  }, []);

  // Function to handle modal open and set selected components
  const handleOpen = (components) => {
    setSelectedComponents(components);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Table columns with the visibility icon to show the modal
  const columns = [
    {
      name: "Supplier ID",
      selector: (row) => row.supplierId,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Supplier Name",
      selector: (row) => row.supplierName,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Supplier Code",
      selector: (row) => row.supplierCode,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Components",
      cell: (row) => (
        <IconButton onClick={() => handleOpen(row.components)}>
          <VisibilitySharpIcon />
        </IconButton>
      ),
      sortable: false,
      minWidth: "150px",
    },
    // {
    //   name: "Admin Email",
    //   selector: (row) => row.adminMailId,
    //   sortable: true,
    //   minWidth: "200px",
    // },
    // {
    //   name: "Mobile Number",
    //   selector: (row) => row.mobileNumber,
    //   sortable: true,
    //   minWidth: "150px",
    // },
    {
      name: "Site",
      selector: (row) => row.site,
      sortable: true,
      minWidth: "150px",
    },
    // {
    //   name: "Address",
    //   selector: (row) => row.address,
    //   sortable: true,
    //   minWidth: "250px",
    // },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "State",
      selector: (row) => row.state,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Postal Code",
      selector: (row) => row.postalCode,
      sortable: true,
      minWidth: "150px",
    },
    // {
    //   name: "Street",
    //   selector: (row) => row.street,
    //   sortable: true,
    //   minWidth: "200px",
    // },
    // {
    //   name: "Components",
    //   cell: (row) => (
    //     <IconButton onClick={() => handleOpen(row.components)}>
    //       <VisibilitySharpIcon />
    //     </IconButton>
    //   ),
    //   sortable: false,
    //   minWidth: "150px",
    // },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        pagination
        dense
        selectableRowsHighlight={true}
        highlightOnHover={true}
        customStyles={CustomStyles}
      />

      {/* Modal to display components */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Components List</DialogTitle>
        <DialogContent>
          {selectedComponents.length > 0 ? (
            <ul>
              {selectedComponents.map((component) => (
                <li style={{fontFamily:'Inter',fontSize:'14px',fontWeight:'bold'}} key={component.componentId}>
                  {component.componentName} ({component.componentCode})
                </li>
              ))}
            </ul>
          ) : (
            <Typography>No components available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SupplierList;
