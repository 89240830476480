import DataTable from "react-data-table-component";
import React, { useEffect, useState, useRef } from "react";
import { GetApi } from "../../Api/Dropdown";
import {useToken} from "../../store";
import { Box, Drawer, Button, Typography } from "@mui/material";
import { Card, Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import CustomStyles from "../../CustomStyles";
const ComponentList = () => {
  const LinesComponent = ({ data }) => {
    const subColumns = [
      {
        name: "Component Code",
        selector: (row) => row.componentCode,
        sortable: true,
        minWidth: "130px",
      },
      {
        name: "Component ID",
        selector: (row) => row.componentId,
        sortable: true,
        minWidth: "130px",
      },

      {
        name: "Quantity",
        selector: (row) => row.quantity,
        sortable: true,
        minWidth: "130px",
      },
    ];
    return (
      <>
        <DataTable
          columns={subColumns}
          data={data.compComposition}
          pagination
          dense
          selectableRowsHighlight="true"
          compact
          highlightOnHover="true"
          customStyles={CustomStyles}
        />
      </>
    );
  };

  const [data, setData] = useState([]);
  const token =  sessionStorage.getItem("token");
  const getData = async () => {
    const response = await GetApi("masters/componentList", token);
    console.log(response);
    setData(response.data.primary);
  };

  useEffect(() => {
    getData();
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [showQr, setShowQr] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [compData, setCompData] = useState([]);

  const getComponentLines = async (id) => {
    console.log(id);
    setShow(true);
    try {
      let response = await GetApi(
        `qrCode/compositionBatchesList?componentCode=${id}`,
        token
      );
      console.log(response.data.primary)
      setCompData(response.data.primary[0].inventoryLink);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      name: "Component Name",

      sortable: true,
      minWidth: "130px",
      selector: (row) => row.componentName,
      
    },
    {
      name: "Component Code",
      selector: (row) => row.componentCode,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Component Description",
      selector: (row) => row.componentDescription,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Component Type",
      selector: (row) => row.componentType,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      minWidth: "130px",
    },

    {
      name: "Assemble",
      selector: (row) => row.boqStatus,
      sortable: true,
      minWidth: "130px",
      cell: (row) => (
        <>
         
            <Typography
              variant="p"
              sx={{fontFamily: "Inter",textTransform:'capitalize',fontWeight:'bold',fontSize:'12px' }}
            >
              {row.boqStatus}
            </Typography>
      
        </>
      ),
    },
  ];

  const subColumns = [
    {
      name: "QR Code",
      minWidth: "130px",
      sortable: true,
      cell: (row) => (
        <>
          <img
            src={row.qrCode}
            style={{ cursor: "pointer" }}
            alt="QR Code"
            width={20}
            height={20}
            onClick={() => {
              setShowImg(row);
              setShowQr(true);
            }}
          />
        </>
      ),
    },
    {
      name: "Component Id",
      selector: (row) => row.componentId,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Batch Number",
      selector: (row) => row.batchNumber,
      sortable: true,
      minWidth: "130px",
    },
    {
      name: "Serial Number",
      selector: (row) => row.serialNumber,
      sortable: true,
      minWidth: "130px",
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        //defaultSortField="templateID"
        pagination
        dense
        selectableRowsHighlight="true"
        compact
        highlightOnHover="true"
        expandableRows
        expandableRowsComponent={LinesComponent}
        customStyles={CustomStyles}
      />

      <Drawer open={show} onClose={handleClose} anchor={"right"}>
        <Card>
          <Card.Header as="h6">
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <CloseIcon
                onClick={handleClose}
                sx={{ alignSelf: "end", color: "black", cursor: "pointer" }}
              />
            </Box>
          </Card.Header>
          <Card.Body>
            <DataTable columns={subColumns} data={compData}  />
          </Card.Body>
        </Card>
      </Drawer>

      <Modal show={showQr}>
        <Modal.Header closeButton>
          <Modal.Title>
            QR Code :- ComponentID - {showImg.componentId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <img src={showImg.qrCode} style={{ width: "60%" }}></img>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowQr(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ComponentList;
