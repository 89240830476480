import React from "react";
import Login from "./Auth/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TopHeader from "./Helpers/TopHeader";
import ComponentMaster from "./Components/Master/ComponentMaster";
import SupplierComponent from "./Components/Master/SupplierComponent";
import TrackingParts from "./Components/TrackingParts";
import Footer from "./Helpers/Footer";
import FinalComposition from "./Components/Master/FinalComposition";
import FinalProduct from "./Components/FinalProduct";
import "./App.css";
import InwardGoods from "./InwardGoods";
import ServiceHistory from "./Components/RepairShop/ServiceHistory";
import Service from "./Components/RepairShop/Service";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              <div className="wrapper">
                <TopHeader />
                <div className="content myappcontent">
                  <div className="container-fluid">
                    <Routes>
                      <Route
                        path="/componentMaster"
                        element={<ComponentMaster />}
                      />
                      <Route
                        path="/supplierMaster"
                        element={<SupplierComponent />}
                      />
                      <Route path="/finalProd" element={<FinalProduct />} />
                      <Route path="/inventory" element={<InwardGoods />} />
                      <Route path="/finalPart" element={<FinalComposition />} />
                      <Route path="/tracking" element={<TrackingParts />} />
                      <Route
                        path="/servicehistoryadmin"
                        element={<ServiceHistory />}
                      />
                      <Route path="/service" element={<Service />} />
                    </Routes>
                  </div>
                </div>
                <Footer />
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
