import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";

function FinalComposition() {
  const [compDropdown, setComponentDropDown] = useState([]);
  const [supDropdown, setSupplierDropDown] = useState([]);

  useEffect(() => {
    // fetchData();
  }, []);

  const { control, handleSubmit, register,reset } = useForm({
    defaultValues: {
      prodID: "",
      prodName: "",
      category: "",
      description: "",
      array: [
        {
          compid: "",
          compname: "",
          subcomponents: [{ subid: "", subname: "" }],
        },
      ],
    },
  });

  const {
    fields: componentFields,
    append: appendComponent,
    remove: removeComponent,
    update: updateComponent,
  } = useFieldArray({
    control,
    name: "array",
  });

  const onSubmit = (data) => {
    console.log("Form data:", data);
  };

  const handleAddComponent = () => {
    appendComponent({
      compid: "",
      compname: "",
      subcomponents: [{ subid: "", subname: "" }],
    });
  };

  const handleAddSubcomponent = (index) => {
    const updatedComponent = {
      ...componentFields[index],
      subcomponents: [
        ...componentFields[index].subcomponents,
        { subid: "", subname: "" },
      ],
    };
    updateComponent(index, updatedComponent);
  };

  const handleRemoveComponent = (index) => {
    removeComponent(index);
  };

  const handleRemoveSubcomponent = (componentIndex, subIndex) => {
    const updatedSubcomponents = componentFields[
      componentIndex
    ].subcomponents.filter((_, i) => i !== subIndex);

    const updatedComponent = {
      ...componentFields[componentIndex],
      subcomponents: updatedSubcomponents,
    };

    updateComponent(componentIndex, updatedComponent);
  };

  return (
    <>
      <div className="row mb-2">
        <div className="col-sm-6">
          <h2 className="m-0 text-dark">Products</h2>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="labelText" htmlFor="prodID">
                    Product ID
                  </label>
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    placeholder="Product ID"
                    {...register("prodID")}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="labelText" htmlFor="prodName">
                    Product Name
                  </label>
                  <input
                    id="prodName"
                    className="form-control form-control-sm"
                    type="text"
                    placeholder="Product Name"
                    {...register("prodName")}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="labelText" htmlFor="description">
                    Description
                  </label>
                  <input
                    id="description"
                    className="form-control form-control-sm"
                    type="text"
                    placeholder="Description"
                    {...register("description")}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="labelText" htmlFor="category">
                    Category
                  </label>
                  <input
                    id="category"
                    className="form-control form-control-sm"
                    type="text"
                    placeholder="Category"
                    {...register("category")}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <div style={{ marginTop: "34px" }} />
                  <Button
                    variant="contained"
                    type="button"
                    onClick={handleAddComponent}
                    size="small"
                  >
                    Add Component
                  </Button>
                </div>
              </div>
            </div>

            {componentFields.map((component, index) => (
              <div
                key={component.id}
                style={{ marginTop: "20px", border: "2px solid black" }}
                className="card-body"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <h5 style={{ fontFamily: "inter" }}>Component {index + 1}</h5>
                  <Button
                    variant="contained"
                    type="button"
                    onClick={() => handleAddSubcomponent(index)}
                    size="small"
                  >
                    Add SubComponent
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="button"
                    onClick={() => handleRemoveComponent(index)}
                    size="small"
                  >
                    Remove Component
                  </Button>
                </div>

                {/* First Row of Component Fields */}
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        className="labelText"
                        htmlFor={`componentCode-${index}`}
                      >
                        Component Code
                      </label>
                      <select
                        className="form-select form-select-sm"
                        name="componentCode"
                        {...register(`array.${index}.componentCode`)}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select Component
                        </option>
                        {/* {compDropdown.map((data, index) => (
                          <option
                            key={index}
                            value={data}
                          >
                            {data}
                          </option>
                        ))} */}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        className="labelText"
                        htmlFor={`quantity-${index}`}
                      >
                        Quantity
                      </label>
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        placeholder="Quantity"
                        {...register(`array.${index}.quantity`)}
                      />
                    </div>
                  </div>
                </div>

                {/* Render subcomponents */}
                {component.subcomponents.map((subcomponent, subIndex) => (
                  <div key={subIndex} style={{ marginLeft: "20px" }}>
                    {/* First Row of Subcomponent Fields */}
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label
                            className="labelText"
                            htmlFor={`subcomponentId-${index}-${subIndex}`}
                          >
                            Sub-Component ID
                          </label>
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Subcomponent ID"
                            {...register(
                              `array.${index}.subcomponents.${subIndex}.componentId`
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label
                            className="labelText"
                            htmlFor={`quantity-${index}-${subIndex}`}
                          >
                            Quantity
                          </label>
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Supplier ID"
                            {...register(
                              `array.${index}.subcomponents.${subIndex}.quantity`
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <Button
                      variant="outlined"
                      color="secondary"
                      type="button"
                      onClick={() => handleRemoveSubcomponent(index, subIndex)}
                      size="small"
                    >
                      Remove SubComponent
                    </Button>
                  </div>
                ))}
              </div>
            ))}

            {/* Submit button */}
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </>
  );
}

export default FinalComposition;
